<template>
	<div>
		<CCard>
			<CCardBody style="padding-top:5px; padding-bottom:5px;">
				<div>
					<div class="container" style="padding:0px;">
						<div class="px-lg-1x">
							<div v-show="!loadingTable">
								<div class="row">
									<div
										v-for="(p, i) in products"
										:key="i"
										class="col-md-4 col-sm-12 mb-2"
										style="padding-right:5px; padding-left:5px;"
									>
										<div class="bg-gray-100 rounded shadow-sm">
											<div class="text-center p-1">
												<clazy-load
													:src="
														p.image1
															? basuri.replace(/\/$/, '') + p.image1
															: 'https://upload.wikimedia.org/wikipedia/commons/0/0a/No-image-available.png'
													"
												>
													<img
														:src="
															p.image1
																? basuri.replace(/\/$/, '') + p.image1
																: 'https://upload.wikimedia.org/wikipedia/commons/0/0a/No-image-available.png'
														"
														style="height:175px; width:auto"
														class="img-fluid card-img-top rounded"
													/>
													<div class="preloader" slot="placeholder">
														<img
															src="http://icon-park.com/imagefiles/loading3-blue.gif"
														/>
													</div>
												</clazy-load>
											</div>
											<div class="p-1">
												<div class="row">
													<div class="col-md-12">
														<span class="text-dark text-responsive float-left">
															<strong>{{ trimName(p.name, 25) }}</strong>
														</span>
														<span
															class="text-dark text-responsive float-right font-italic"
														>
															<strong>
																Rp {{ p.price ? p.price.toLocaleString() : 0 }}
															</strong>
														</span>
													</div>
												</div>
												<p class="text-muted mb-2">
													{{ p.category.name }}
													<span class="font-italic float-right">{{
														p.winWay
													}}</span>
												</p>
												<p class="small mb-0 float-left">
													<span class="font-weight-bold text-primary">
														participants : {{ p.participant }}
													</span>
												</p>
												<p class="small mb-0 float-right">
													<span class="font-weight-bold text-danger">
														{{ "expired " + expiredTime(p.expired) }}
													</span>
												</p>

												<CButton
													@click="confirmModal(i)"
													class="mt-2"
													block
													:color="
														isExpired(p.expired) ? 'secondary' : 'primary'
													"
													:disabled="isExpired(p.expired)"
												>
													{{ isExpired(p.expired) ? "EXPIRED" : "JOIN" }}
												</CButton>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-show="loadingTable" class="text-center">
								<ellipsis-loader :color="'#54f1d2'" />
							</div>
						</div>
					</div>
				</div>
			</CCardBody>
		</CCard>

		<CModal
			:show.sync="modalShow"
			:close-on-backdrop="false"
			:centered="true"
			:title="'Join ' + selectedProduct.name"
			color="primary"
			size="lg"
		>
			<div class="m-1">
				<div class="mb-3">
					<CCarousel arrows indicators animate>
						<CCarouselItem
							:image="
								selectedProduct.image1 != null
									? basuri.replace(/\/$/, '') + selectedProduct.image1
									: 'https://upload.wikimedia.org/wikipedia/commons/0/0a/No-image-available.png'
							"
						/>
						<CCarouselItem
							:image="
								selectedProduct.image2 != null
									? basuri.replace(/\/$/, '') + selectedProduct.image2
									: 'https://upload.wikimedia.org/wikipedia/commons/0/0a/No-image-available.png'
							"
						/>
						<CCarouselItem
							:image="
								selectedProduct.image3 != null
									? basuri.replace(/\/$/, '') + selectedProduct.image3
									: 'https://upload.wikimedia.org/wikipedia/commons/0/0a/No-image-available.png'
							"
						/>
						<CCarouselItem
							:image="
								selectedProduct.image4 != null
									? basuri.replace(/\/$/, '') + selectedProduct.image4
									: 'https://upload.wikimedia.org/wikipedia/commons/0/0a/No-image-available.png'
							"
						/>
					</CCarousel>
				</div>
				<div class="row">
					<div class="col-md-12">
						<span class="text-dark text-responsive float-left">
							<h5>{{ selectedProduct.name }}</h5>
						</span>
						<span class="text-dark text-responsive float-right">
							<h5>
								Rp
								{{
									selectedProduct.price
										? selectedProduct.price.toLocaleString()
										: 0
								}}
							</h5>
						</span>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<p class="text-muted m1-2 float-left">
							{{ selectedProduct.category }}
						</p>
						<p class="text-muted m1-2 float-right">
							{{ selectedProduct.winWay }}
						</p>
					</div>
				</div>
				<h5
					class="font-weight-bold text-primary float-left font-italic text-responsive"
				>
					participants : {{ selectedProduct.participant }}
				</h5>
				<h5
					class="font-weight-bold text-primary float-right font-italic text-responsive"
				>
					{{
						"expired " +
							expiredTime(selectedProduct.expired) +
							" (" +
							new Date(selectedProduct.expired * 1000)
								.toISOString()
								.split("T")[0] +
							")"
					}}
				</h5>
				<br />
				<p class="text-muted mt-2">
					<span class="font-weight-bold font-italic">Description:</span><br />
					<span v-html="deentitize(selectedProduct.description)"></span>
				</p>
				<p class="text-muted m1-2">
					<span class="font-weight-bold font-italic">TOS:</span><br />
					<span v-html="deentitize(selectedProduct.tos)"></span>
				</p>
			</div>
			<hr />
			<span class="float-left">
				<input type="checkbox" id="checkbox" v-model="approve" />
				<label class="font-italic text-responsive" for="checkbox">
					&nbsp;&nbsp;I've read the TOS of the requirements above
				</label>
			</span>
			<template #footer>
				<CButton
					@click="take"
					class="mt-2"
					block
					color="success"
					:disabled="!approve"
					v-show="!loadingTake"
				>
					TAKE IT
				</CButton>
				<CButton color="secondary" @click="modalShow = false">
					CLOSE
				</CButton>
				<div v-show="loadingTake" class="text-center">
					<ellipsis-loader :color="'#54f1d2'" />
				</div>
			</template>
		</CModal>
	</div>
</template>

<script>
import { VueClazyLoad } from "vue-clazy-load";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { basuri, debounce } from "@/plugins/utils";

dayjs.extend(relativeTime);

export default {
	components: {
		ClazyLoad: VueClazyLoad,
	},
	data() {
		return {
			products: [],
			dayjs,
			basuri,
			index: null,
			modalShow: false,
			selectedProduct: {
				name: "",
				category: "",
				description: "",
				tos: "",
				expired: "",
				id: 0,
				participant: 0,
				image1: null,
				image2: null,
				image3: null,
				image4: null,
				price: 0,
				winWay: "",
			},
			loadingTable: false,
			loadingTake: false,
			approve: false,
		};
	},
	mounted() {
		this.getProducts();
	},
	methods: {
		confirmModal(i) {
			this.index = i;
			this.modalShow = true;
			this.approve = false;
			this.selectedProduct = {
				name: this.products[i].name,
				description: this.products[i].description,
				tos: this.products[i].tos,
				expired: this.products[i].expired,
				id: this.products[i].id,
				participant: this.products[i].participant,
				category: this.products[i].category.name,
				image1: this.products[i].image1,
				image2: this.products[i].image2,
				image3: this.products[i].image3,
				image4: this.products[i].image4,
				price: this.products[i].price,
				winWay: this.products[i].winWay,
			};
		},
		getProducts: debounce(async function() {
			this.loadingTable = true;
			const { data } = await this.$http.get("campaign", {
				params: { sort: "expired", order: "desc" },
			});
			if (data.status == "success") {
				this.products = data.data.data;
				this.loadingTable = false;
			}
		}, 500),
		expiredTime(time) {
			return this.dayjs(time * 1000).fromNow();
		},
		isExpired(time) {
			const t = Date.now() - time * 1000;
			return t < 0 ? false : true;
		},
		take: debounce(async function() {
			this.loadingTake = true;
			try {
				const { data } = await this.$http.post("campaign", {
					product: this.selectedProduct.id,
				});

				if (data.status == "success") {
					this.$toast.success("Success ! " + data.message);
					this.getProducts();
				} else {
					this.$swal("Fail !", data.message, "error");
				}
			} catch (error) {
				this.$swal("Error !", error.message, "error");
			}
			this.loadingTake = false;
			this.modalShow = false;
		}, 500),
		deentitize(str) {
			let ret = str.replace(/&gt;/g, ">");
			ret = ret.replace(/&lt;/g, "<");
			ret = ret.replace(/&quot;/g, '"');
			ret = ret.replace(/&apos;/g, "'");
			ret = ret.replace(/&amp;/g, "&");
			return ret;
		},
		trimName(str, length) {
			if (str.length < length) return str;
			return str.substring(0, length) + "...";
		},
	},
};
</script>

<style lang="css">
.text-responsive {
	font-size: calc(100% + 0.1vw + 0.1vh);
}
</style>
